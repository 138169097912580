import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import api from '../../../../configs/api';
import elvisApi from '../../../../helpers/elvisApi';
import cookies from '../../../../helpers/cookies';

const Logout = () => {
  api.signOut();
  elvisApi.logout();
  cookies.remove('branding-jwt');
  cookies.remove('branding-csrf');

  return <Redirect to={{ pathname: '/' }} />;
}

export default Logout;
