import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router, Route} from 'react-router-dom';
import './index.css';
import App from './App';
import '@fortawesome/fontawesome-free/css/all.css';
import withTracker from './helpers/withTracker';
import AppStateProvider from './providers/AppState'

ReactDOM.render(
  <AppStateProvider>
    <Router>
      <Route component={withTracker(App)} />
    </Router>
  </AppStateProvider>,
  document.getElementById('root'),
);
