import React from "react";
import { Route, Switch } from "react-router-dom";
import './ResetPassword.css';
import SetNewPassword from "./SetNewPassword";
import ResetPasswordRequest from "./ResetPasswordRequest";

const ResetPassword = (props) => {

  return (
    <div className='ems-reset-password ems-main'>
      <div className="ems-main-inner">
        <Switch>
          <Route exact path="/auth/reset">
            <ResetPasswordRequest resetPasswordURLforNonStrapiUser={props.resetPasswordURLforNonStrapiUser}/>
          </Route>
          <Route path="/auth/reset/:code">
            <SetNewPassword />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

ResetPassword.defaultProps = {};
ResetPassword.propTypes = {};

export default ResetPassword;
