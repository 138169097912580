import cookies from './cookies';
import qs from 'qs';

function handleResponse(response) {
  return new Promise((resolve, reject) => {
    response.json().then(jsonBody => {
      if (response.ok) {
        return resolve(jsonBody);
      }

      if (jsonBody.message === "not logged in" || jsonBody.message === "Invalid token.") {
        this.removeToken();
        window.location.href = "/";
      }

      return reject({
        status: response.status,
        headers: response.headers,
        body: jsonBody,
      });
    })
      .catch(() => (response.ok ? resolve(response) : reject(response)));
  });
}

export default class Api {
  constructor(url) {
    this._url = url;
    this._token = cookies.get('branding-jwt');
  }

  get token() {
    return this._token;
  }

  set token(newToken) {
    this._token = newToken;
    const date = new Date();
    date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
    cookies.set('branding-jwt', newToken, { expires: date });
  }

  removeToken() {
    this._token = undefined;
    cookies.remove('branding-jwt');
  }

  header(customHeader) {
    let headers = {
      'Content-Type': 'application/json',
    };

    if (this.token) {
      headers['Authorization'] = `Bearer ${this.token}`;
    }

    // if (this._csrf) {
    //   headers['X-CSRF-TOKEN'] = this._csrf;
    // }

    if (customHeader) {
      headers = {
        ...headers,
        ...customHeader
      };
    }

    return headers;
  }

  getSettings() {
    return fetch(`${this._url}/setting`, {
      method: 'get',
      mode: 'cors',
      headers: this.header(),
    })
      .then(handleResponse.bind(this));
  }

  getMenus() {
    return fetch(`${this._url}/menus`, {
      method: 'get',
      mode: 'cors',
      headers: this.header(),
    })
      .then(handleResponse.bind(this));
  }

  forgotPassword(email) {
    return fetch(`${this._url}/auth/forgot-password`, {
      method: 'post',
      mode: 'cors',
      body: JSON.stringify({ email }),
      headers: this.header(),
    })
      .then(handleResponse.bind(this));
  }

  resetPassword(code, password, passwordConfirmation) {
    return fetch(`${this._url}/auth/reset-password`, {
      method: 'post',
      mode: 'cors',
      body: JSON.stringify({ 
        code,
        password,
        passwordConfirmation
      }),
      headers: this.header(),
    })
      .then(handleResponse.bind(this));
  }

  getUserType(email) {
    return fetch(`${this._url}/setting/user-type/${email}`, {
      method: 'get',
      mode: 'cors',
      headers: this.header(),
    })
      .then(handleResponse.bind(this));
  }

  getMyShares() {
    return fetch(`${this._url}/share/me`, {
      method: 'get',
      mode: 'cors',
      headers: this.header(),
    })
      .then(handleResponse.bind(this));
  }

  getShare(id) {
    return fetch(`${this._url}/share/${id}`, {
      method: 'get',
      mode: 'cors',
      headers: this.header(),
    })
      .then(handleResponse.bind(this));
  }

  getPrivateShare(token, password) {
    return fetch(`${this._url}/share/private/${token}/${password ? password : ''}`, {
      method: 'get',
      mode: 'cors',
      headers: this.header(),
    })
      .then(handleResponse.bind(this));
  }

  browseShare(share, path) {
    return fetch(`${this._url}/elvis/share/${share.id}/browse?path=${encodeURIComponent(path)}`, {
      method: 'get',
      mode: 'cors',
      headers: this.header(),
    })
      .then(handleResponse.bind(this));
  }

  browsePrivateShare(share, path) {
    return fetch(`${this._url}/elvis/share/private/${share.token}/browse?path=${encodeURIComponent(path)}`, {
      method: 'get',
      mode: 'cors',
      headers: this.header(),
    })
      .then(handleResponse.bind(this));
  }

  sharedSearch(share, query) {
    return fetch(`${this._url}/elvis/share/${share.id}/search${qs.stringify(query, { addQueryPrefix: true })}`, {
      method: 'get',
      mode: 'cors',
      headers: this.header(),
    })
      .then(handleResponse.bind(this));
  }

  privateSharedSearch({ token }, query) {
    return fetch(`${this._url}/elvis/share/private/${token}/search${qs.stringify(query, { addQueryPrefix: true })}`, {
      method: 'get',
      mode: 'cors',
      headers: this.header(),
    })
      .then(handleResponse.bind(this));
  }

  sharedFile(share, query) {
    return fetch(`${this._url}/elvis/share/${share.id}/search${qs.stringify(query, { addQueryPrefix: true })}`, {
      method: 'get',
      mode: 'cors',
      headers: this.header(),
    })
      .then(handleResponse.bind(this));
  }

  userSearch(query) {
    return fetch(`${this._url}/elvis/user/search${qs.stringify(query, { addQueryPrefix: true })}`, {
      method: 'get',
      mode: 'cors',
      headers: this.header(),
    })
      .then(handleResponse.bind(this));
  }

  search(query) {
    return fetch(`${this._url}/elvis/search${qs.stringify(query, { addQueryPrefix: true })}`, {
      method: 'get',
      mode: 'cors',
      headers: this.header(),
    })
      .then(handleResponse.bind(this));
  }

  download(ids, fileName = 'download') {
    window.open(`${this._url}/elvis/download?downloadKind=original&assetIds=${ids}`);
  }

  preview(id, maxSize) {
    return `${this._url}/elvis/preview/${maxSize}/${id}`;
  }

  create({ path, metadata, files, createCollection }) {
    let form = new FormData();
    form.append('createCollection', true);
    form.append('path', path);
    form.append('metadata', JSON.stringify(metadata));
    files.forEach(f => form.append('file', f));

    return fetch(`${this._url}/elvis/create`, {
      method: 'put',
      mode: 'cors',
      credentials: 'include',
      body: form,
      headers: {
        // let it set its own multipart headers
        'Authorization': `Bearer ${this.token}`
      }
    })
      .then(handleResponse.bind(this));
  }

  signIn(identifier, password) {
    this._csrf = cookies.get('branding-csrf')
    return fetch(`${this._url}/auth/elvis?identifier=${encodeURIComponent(identifier)}&password=${encodeURIComponent(password)}`, {
      method: 'get',
      mode: 'cors',
      headers: this.header(),
    })
      .then(handleResponse.bind(this))
      .then(
        response => {
          this.token = response.jwt;
          return Promise.resolve(response);
        },
        rejection => {
          return Promise.reject(rejection);
        }
      );
  }

  signOut() {
    this.token = null;
  }

  getHierarchy(name) {
    return fetch(`${this._url}/hierarchy/${name}?_sort=sorting:desc`, {
      method: 'get',
      mode: 'cors',
      headers: this.header(),
    })
      .then(handleResponse.bind(this));
  }

  getHierarchies() {
    return fetch(`${this._url}/hierarchy/list`, {
      method: 'get',
      mode: 'cors',
      headers: this.header(),
    })
      .then(handleResponse.bind(this));
  }

  getAssets(params) {
    return fetch(`${this._url}/assets?q=${params.q}`, {
      method: 'get',
      mode: 'cors',      
      headers: this.header(),
    })
      .then(handleResponse.bind(this));
  }

  getArticleCategories() {
    return fetch(`${this._url}/articles/categories`, {
      method: 'get',
      mode: 'cors',
      headers: this.header(),
    })
      .then(handleResponse.bind(this));
  }

  getArticleCategorySettings() {
    return fetch(`${this._url}/article-category-settings`, {
      method: 'get',
      mode: 'cors',
      headers: this.header(),
    })
      .then(handleResponse.bind(this));
  }

  getArticles(categoryName, page = 0, limit = 10, keyword = '') {
    return fetch(`${this._url}/articles${categoryName ? `/category/${categoryName}` : ''}?_start=${page * limit}&_limit=${limit}${keyword ? `&_q=${keyword}` : ''}`, {
      method: 'get',
      mode: 'cors',
      headers: this.header(),
    })
      .then(handleResponse.bind(this));
  }

  getArticle(id) {
    return fetch(`${this._url}/articles/${id}`, {
      method: 'get',
      mode: 'cors',
      headers: this.header(),
    })
      .then(handleResponse.bind(this));
  }

  getArticleImages(id) {
    return fetch(`${this._url}/articles/${id}/fetchPreviews`, {
      method: 'get',
      mode: 'cors',
      headers: this.header(),
    })
      .then(handleResponse.bind(this));
  }

  getArticlesImages(ids) {
    return fetch(`${this._url}/articles/fetchThumbnails?ids=${ids.join(',')}`, {
      method: 'get',
      mode: 'cors',
      headers: this.header(),
    })
      .then(handleResponse.bind(this));
  }

  getLegalNotice(id) {
    return fetch(`/elvis/share/legal/${id}`, {
      method: 'get',
      mode: 'cors',
      headers: this.header(),
    });
  }

  getPreviewURL(id, page) {
    return `${this._url}/elvis/preview/${id}${page ? `/page/${page}` : ''}`;
  }

  getPrivatePreviewURL(id, token) {
    return `${this._url}/elvis/preview/${id}/private/${token}`
  }

  getMediaPreviewURL(id) {
    return `${this._url}/elvis/previewMedia/${id}`
  }

  getPrivateMediaPreviewURL(id, token) {
    return `${this._url}/elvis/previewMedia/${id}/private/${token}`
  }

  getCustomPreviewURL(id, size) {
    return `${this._url}/elvis/preview/${size}/${id}`
  }

  getPrivateCustomPreviewURL(id, size, token) {
    return `${this._url}/elvis/preview/${size}/${id}/private/${token}`
  }

  getThumbnailURL(id) {
    return `${this._url}/elvis/thumbnail/${id}`
  }

  getPrivateThumbnailURL(id, token) {
    return `${this._url}/elvis/thumbnail/${id}/private/${token}`
  }

  getFileURL(id) {
    return `${this._url}/elvis/file/${id}`
  }

  getOriginalMedia(id) {
    return `${this._url}/elvis/originalMedia/${id}`
  }

  getPrivateOriginalMedia(id, token) {
    return `${this._url}/elvis/originalMedia/${id}/private/${token}`
  }

  getPrivateFileURL(id, token) {
    return `${this._url}/elvis/share/private/${token}/file/${id}`
  }

  getSharedFileURL(share, id) {
    return `${this._url}/elvis/share/${share.id}/file/${id}`
  }

  getDownloadURL() {
    return `${this._url}/elvis/download`
  }

  getPrivateDownloadURL(token) {
    return `${this._url}/elvis/share/private/${token}/download`
  }

  getSharedDownloadURL(share) {
    return `${this._url}/elvis/share/${share.id}/download`
  }

  getUserDownloadURL() {
    return `${this._url}/elvis/user/download`
  }

  getSharedFileHLS(share, id) {
    return fetch(`${this._url}/elvis/share/${share.id}/hls/${id}`).then(handleResponse.bind(this));
  }

  getPrivateFileHLS(token, id) {
    return fetch(`${this._url}/elvis/share/private/${token}/hls/${id}`).then(handleResponse.bind(this));
  }

  getAssetThumbnail(asset, token = null) {
    const assetId = asset.thumbnailUrl ? asset.id : asset.thumbnailHits && asset.thumbnailHits.length > 0 ? asset.thumbnailHits[0].id : null;
    if (assetId) {
      if (token) {
        return this.getPrivateThumbnailURL(assetId, token);
      } else {
        return this.getThumbnailURL(assetId);
      }
    } else {
      return ''
    }
  }
  
  async getRelatedAssetLists(ids) {
    const requests = ids.map(id => 
      fetch(`${this._url}/related-asset-lists/${id}`, {
        method: 'get',
        mode: 'cors',
        headers: this.header(),
      })
      .then(handleResponse.bind(this))
    );

    const results = await Promise.all(requests);

    return Promise.resolve(results);
  }

  getSingleType(id) {
    return fetch(`${this._url}/${id}`, {
      method: 'get',
      mode: 'cors',
      headers: this.header(),
    })
      .then(handleResponse.bind(this));
  }

  getSpotifyID(isrc) {
    return fetch(`${this._url}/setting/spotify-id/${isrc}`, {
      method: 'get',
      mode: 'cors',
      headers: this.header(),
    })
    .then(handleResponse.bind(this));
  }
}
