import React, { Component } from 'react';
import './Hierarchy.css';
import qs from 'qs';
import { Link } from 'react-router-dom';
import SearchBar from '../../components/SearchBar/SearchBar';
import BreadCrumb from '../../components/BreadCrumb/BreadCrumb';
import HierarchyList from '../../components/HierarchyList/HierarchyList';
import HierarchyCollectionList from '../../components/HierarchyCollectionList/HierarchyCollectionList';

class Hierarchy extends Component {
  handleSearch(keyword) {
    this.props.search({
      keyword: keyword,
      page: 0,
    });
  }

  render() {
    const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const hideHierarchies = queryParams.hideHierarchies === 'true';

    return (
      <React.Fragment>
        <BreadCrumb visibleDeepness={1} breadcrumb={this.props.breadcrumb} url={path => `/hierarchies/${path}`} collection={this.props.collection} />
        <SearchBar
          placeholder={this.props.searchSettings.bar.placeholder}
          buttonText={this.props.searchSettings.button.text}
          keyword={queryParams.keyword}
          reset={() => this.props.reset()}
          search={query => this.handleSearch(query)}/>
        
        {(this.props.hierarchy.children.length > 0 || this.props.hierarchy.collectionList) && 
          <div className='ems-hierarchies-results-wrapper'>
            <div className='ems-heading-wrapper ems-hierarchies-list-heading-wrapper'>
              {(this.props.hierarchy.hierarchyHeadline || this.props.defaultHeadline) && <h2 className='ems-heading ems-hierarchies-list-heading'>{this.props.hierarchy.hierarchyHeadline || this.props.defaultHeadline}</h2>}
              {(this.props.hierarchy.children.length > 0 || this.props.hierarchy.collectionList) && <Link title={hideHierarchies ? `Show ${this.props.hierarchy.hierarchyHeadline ? this.props.hierarchy.hierarchyHeadline : this.props.defaultHeadline || 'Hierarchy'}` : `Hide ${this.props.hierarchy.hierarchyHeadline ? this.props.hierarchy.hierarchyHeadline : this.props.defaultHeadline || 'Hierarchy'}`} className={`ems-button ems-toggle ems-button-icon ems-hierarchies-list-toggle ${hideHierarchies ? 'open' : ''}`}  to={{ search: qs.stringify({ ...queryParams, hideHierarchies: !hideHierarchies }, { addQueryPrefix: true }) }}>
                {!hideHierarchies && <i className='far fa-minus-square' aria-hidden='true'></i>}
                {hideHierarchies && <i className="far fa-plus-square" aria-hidden='true'></i>}
              </Link>}
            </div>
            <div className={`ems-hierarchies-list-wrapper ${hideHierarchies ? 'ems-hierarchies-list-wrapper-hidden' : ''}`}>
              {this.props.hierarchy.children.length > 0 && <HierarchyList showPreviewAsThumbnail={this.props.settings.general.showPreviewAsThumbnail} hierarchyList={this.props.hierarchy.children}/>}

              {this.props.hierarchy.collectionList && 
                <div className='ems-hierarchy-collections-list-wrapper'>
                  {this.props.hierarchy.collectionListTitle && <h3 className='ems-heading ems-hierarchy-collections-list-title'>{this.props.hierarchy.collectionListTitle}</h3>}
                  {this.props.hierarchy.collectionList.split(',').map((collectionId, index) =>
                    <HierarchyCollectionList key={index} settings={this.props.settings} collectionId={collectionId}/>
                  )}
                </div>
              }
            </div>
          </div>}
      </React.Fragment>
    );
  }
}

export default Hierarchy;