import React, { Component } from 'react';
import './ContributeUploader.css';
import Dropzone from 'react-dropzone';

class ContributeUploader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      files: []
    };
  }

  onDrop(files) {
    this.setState({
      files: this.state.files.concat(files),
    })
  };

  handleSend() {
    this.props.onSendFiles(this.state.files);
  }

  removeFile(removedFile) {
    const newFiles = this.state.files.filter(file => file.path !== removedFile.path);

    this.setState({
      files: newFiles,
    });
  }

  render() {
    const files = this.state.files.map(file => (
      <li className='ems-contribute-uploader-files-list-item' key={file.name}>
        <div className='ems-contribute-uploader-files-name'>
          {file.name}
          <span className='ems-contribute-uploader-files-size'>{file.size} bytes</span>  
        </div>
        <button className='ems-contribute-uploader-files-remove-button' onClick={() => this.removeFile(file)}>Remove</button>
      </li>
    ));

    return (
      <div>
        <Dropzone onDrop={this.onDrop.bind(this)}>
          {({getRootProps, getInputProps}) => (
            <section className='ems-contribute-uploader'>
              <div {...getRootProps({className: 'dropzone'})} className='ems-contribute-uploader-dropzone'>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
              </div>
              <aside className='ems-contribute-uploader-files'>
                {this.state.files.length > 0 && <span className='ems-contribute-uploader-files-title'>Selected files:</span>}
                <ul className='ems-contribute-uploader-files-list'>{files}</ul>
              </aside>
              {this.state.files.length > 0 && <button type='button' className='ems-button ems-contribute-uploader-submit' onClick={this.handleSend.bind(this)}>Send Files</button>}
            </section>
          )}
        </Dropzone>

        
      </div>
    );
  }
}

export default ContributeUploader;
