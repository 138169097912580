import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import './ArticleCategoryList.css';

class ArticleCategoryList extends Component {
  get categorySettings() {
    const categorySettings = {};
    this.props.articleCategorySettings.forEach(articleCategorySetting => {
      categorySettings[articleCategorySetting.name] = articleCategorySetting;
    });
    return categorySettings;
  }

  render() {
    return (
      <div className='ems-category-wrapper ems-articles-category-wrapper'>
        <div className='ems-heading-wrapper ems-articles-heading-wrapper'>
          <h4 className='ems-heading ems-articles-category-heading'>Categories</h4><button className={`ems-button ems-button-icon ems-toggle ems-category-toggle ems-articles-category-toggle ${this.props.ui.showCategories ? 'ems-categories-toggle-open' : 'ems-categories-toggle-hidden'}`} href='/' onClick={e => this.props.toggleCategories(e)}>{this.props.ui.showCategories ? <i className='far fa-plus-square' aria-hidden='true'></i> : <i className='far fa-minus-square' aria-hidden='true'></i>}</button>
        </div>
        {
          <div className={`ems-articles-category-list ${this.props.ui.showCategories ? 'ems-articles-category-list-hidden' : 'ems-articles-category-list-open'}`}>
            <NavLink className='ems-articles-category-list-item' to={`/articles`} exact>
              {this.categorySettings.All && this.categorySettings.All.categoryThumbnail && <div className='ems-thumbnail ems-articles-category-list-item-thumbnail'>
                <div className='ems-thumbnail-inner ems-articles-category-list-item-thumbnail-inner' style={{ backgroundImage: `url(http://localhost:1337/elvis/preview/${this.categorySettings.All.categoryThumbnail})` }}>
                </div>
              </div>}
              All
            </NavLink>
            {this.props.categories.map(category =>
              <NavLink key={category._id} className='ems-articles-category-list-item' data-name={`/articles/category/${category.name}`} to={`/articles/category/${category.name}`}>

                {this.categorySettings[category.name] && this.categorySettings[category.name].categoryThumbnail && <div className='ems-thumbnail ems-articles-category-list-item-thumbnail'>
                  <div className='ems-thumbnail-inner ems-articles-category-list-item-thumbnail-inner' style={{ backgroundImage: `url(http://localhost:1337/elvis/preview/${this.categorySettings[category.name].categoryThumbnail})` }}>
                  </div>
                </div>}
                {category.name}
              </NavLink>)}
          </div>
        }
      </div>
    );
  }
}

export default ArticleCategoryList;