import React, { Component } from 'react';
import './Contribute.css';
import api from '../../configs/api';
import { AppStateConsumer } from '../../providers/AppState';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import ContributeForm from './ContributeForm/ContributeForm';
import ContributeUploader from './ContributeUploader/ContributeUploader';
import ArticleSectionContainer from '../../components/ArticleSectionContainer/ArticleSectionContainer';

class Contribute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ui: {
        contributeSettingsLoading: false,
        contributeSettingsSuccess: false,

        sendingFiles: false,
        sendingFilesSuccess: false,
        sendingFilesFail: false,
        sendingFilesErrorMessage: '',
      },

      contributeSettings: {},

      contributeFormData: {
        submitted: false,
        values: {},
      }
    };
  }

  componentDidMount() {
    if (this.props.settingsLoadingSuccess) {
      this.init();
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.settingsLoadingSuccess && (prevProps.settingsLoadingSuccess !== this.props.settingsLoadingSuccess)) {
      this.init();
    }
  }

  async init() {
    this.setState({
      ui: {
        ...this.state.ui,
        contributeSettingsLoading: true,
        contributeSettingsSuccess: false,
      }
    });
    try {
      const contributeSettings = await api.getSingleType('contribute-page');
      this.setState({
        contributeSettings,
        ui: {
          ...this.state.ui,
          contributeSettingsLoading: false,
          contributeSettingsSuccess: true,
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  get settings() {
    if (!this.props.settingsLoadingSuccess) {
      return {};
    }

    const settingsList = this.props.settings;
    const general = settingsList.find(({ name }) => name === 'general').config;
    const style = settingsList.find(({ name }) => name === 'style').config;

    return {
      general,
      style
    };
  }

  handleContributeFormSubmit(values) {
    this.setState({
      contributeFormData: {
        submitted: true,
        values
      }
    })
  }

  getDynamicMetadataValue(value) {
    if (value === 'username') {
      return sessionStorage.userIdentifier || '';
    }

    if (value === 'todaysdate') {
      return (new Date()).getTime();
    }
  }

  get uploadMetadata() {
    const { metaDataList } = this.state.contributeSettings;
    const hiddenMetadata = {};
    
    metaDataList.map(metaDataListItem => ({ 
      metadata: metaDataListItem.metadata, 
      value: metaDataListItem.variable ? this.getDynamicMetadataValue(metaDataListItem.value) : metaDataListItem.value
    }))
    .forEach(item => {
      hiddenMetadata[item.metadata] = item.value;
    });

    return {
      ...hiddenMetadata,
      ...this.state.contributeFormData.values,
    }
  }

  async handleSendFiles(files) {
    const uploadFolderPath = `${this.state.contributeSettings.uploadFolderPath}/upload_${Date.now()}_${Math.floor(Math.random() * 100)}`;
    const payload = {
      createCollection: true,
      path: uploadFolderPath,
      metadata: this.uploadMetadata,
      files
    };

    try {
      this.setState({
        ui: {
          ...this.state.ui,
          sendingFiles: true,
        }
      });
      let response = await api.create(payload);

      // > SUCCESS /////
      this.setState({
        ui: {
          ...this.state.ui,
          sendingFiles: false,
          sendingFilesSuccess: true,
        }
      });
    } catch(error) {
      this.setState({
        ui: {
          ...this.state.ui,
          sendingFiles: false,
          sendingFilesFail: true,
          sendingFilesErrorMessage: (error && error.message) || '',
        }
      });
    }
  }

  reset() {
    this.setState({
      ui: {
        ...this.state.ui,
        sendingFiles: false,
        sendingFilesSuccess: false,
        sendingFilesFail: false,
        sendingFilesErrorMessage: '',
      },

      contributeFormData: {
        submitted: false,
        values: {},
      }
    })
  }

  render() {
    const { ui, contributeSettings } = this.state;
    const shouldShowContributeForm = 
      ui.contributeSettingsSuccess && contributeSettings.inputList && 
      contributeSettings.inputList.length > 0 && !this.state.contributeFormData.submitted;

    return (
      <ErrorBoundary>
        <div className='ems-container'>

          <Header
            settings={this.settings.general}
            isAuthenticated={true}
            basketList={this.state.basketList} />
          <div className='ems-main'>
            <div className='ems-main-inner'>
              {ui.contributeSettingsSuccess && this.state.contributeSettings.pageLayout && 
                <ArticleSectionContainer 
                  articleSettings={this.state.contributeSettings.pageLayout} 
                  placement='top'/>}
              {ui.contributeSettingsSuccess && !(ui.sendingFiles || ui.sendingFilesSuccess || ui.sendingFilesFail) && 
                <React.Fragment>
                  <div className='ems-contribute-page-header'>
                    <h4 className='ems-contribute-page-name'>{contributeSettings.pageName}</h4>
                    <p className='ems-contribute-page-description'>Contribute your material here. Add your metadata and drag and drop your files to the hotzone and we'll take care of the rest. It's that simple.</p>
                  </div>
                  {shouldShowContributeForm &&
                    <ContributeForm 
                      onSubmit={this.handleContributeFormSubmit.bind(this)}
                      inputList={contributeSettings.inputList}/>}

                  {!shouldShowContributeForm && <ContributeUploader onSendFiles={this.handleSendFiles.bind(this)}/>}
                </React.Fragment>
              }

              {(ui.sendingFiles || ui.sendingFilesSuccess || ui.sendingFilesFail) && <div className='ems-contribute-result'>
                {ui.sendingFiles && !(ui.sendingFilesSuccess || ui.sendingFilesFail) && <div className='ems-contribute-loading'>Please wait, uploading your files...</div>}
                {ui.sendingFilesSuccess && <div className='ems-contribute-success'>
                  <p className='ems-contribute-success-message'>Files uploaded successfully.</p>
                  <button type='button' onClick={this.reset.bind(this)} className='ems-button ems-contribute-back'>Back</button>
                </div>}
                {ui.sendingFilesFail && <div className='ems-contribute-error'>
                  <p>{ui.sendingFilesErrorMessage ? ui.sendingFilesErrorMessage : 'An unknown error has occurred.'}</p>
                  <button type='button' onClick={this.reset.bind(this)} className='ems-button ems-contribute-back'>Back</button>
                </div>}
              </div>
              }

              {ui.contributeSettingsSuccess && this.state.contributeSettings.pageLayout &&
                <ArticleSectionContainer 
                  articleSettings={this.state.contributeSettings.pageLayout} 
                  placement='bottom'/>}
            </div>
          </div>
          <Footer
            settings={this.settings.general} />
        </div>
      </ErrorBoundary>
    );
  }
}

const ConnectedContribute = props => (
  <AppStateConsumer>
    {({ settings, settingsLoading, settingsLoadingSuccess, userIdentifier }) => (
      <Contribute
        {...props}
        userIdentifier={userIdentifier}
        settings={settings}
        settingsLoading={settingsLoading}
        settingsLoadingSuccess={settingsLoadingSuccess}
      />
    )}
  </AppStateConsumer>
);

export default ConnectedContribute;
