import { useQuery, useMutation } from "react-query";
import api from "../../../../configs/api";
import cookies from "../../../../helpers/cookies";

export const usePrivateShare = () =>
  useMutation((credentials) =>
    api.getPrivateShare(credentials.token, credentials.password),
    {
      onSuccess: (response, variables) => {
        sessionStorage.setItem(variables.token, variables.password);
      }
    }
  );
