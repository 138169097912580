import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './BreadCrumb.css';

class BreadCrumb extends Component {
  render() {
    return (
      ((this.props.breadcrumb.length > this.props.visibleDeepness) || (this.props.collection && this.props.collection.metadata)) ? <ul className='ems-margin-top ems-breadcrumb-wrapper'>
        {this.props.breadcrumb.map(breadcrumb => <li key={breadcrumb.id} className='ems-breadcrumb-item'><Link to={this.props.url(breadcrumb.path)}>{breadcrumb.name}</Link></li>)}
        {this.props.collection && this.props.collection.metadata && <li className='ems-breadcrumb-item'>{this.props.collection.metadata.name}</li>}
      </ul> : null
    );
  }
}

export default BreadCrumb;