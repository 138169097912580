import React, { Component } from 'react';
import './PlayAll.css';
import Player from '../Player/Player';
import api from '../../configs/api';
import { Helmet } from 'react-helmet';

class PlayAll extends Component {
  constructor(props) {
    super();
    this.state = {
      activeAsset: props.assets[0].id,
      playOriginal: false,
      showSideBar: false,
    };
  }

  get activeAsset() {
    return this.props.assets.find(asset => asset.id === this.state.activeAsset);
  }

  get previewFile() {
    if (this.props.token) {
      return api.getPrivateMediaPreviewURL(this.state.activeAsset, this.props.token);
    } else {
      return api.getMediaPreviewURL(this.state.activeAsset);
    }
  }

  get originalFile() {
    if (this.props.token) {
      return api.getPrivateOriginalMedia(this.state.activeAsset, this.props.token);
    } else {
      return api.getOriginalMedia(this.state.activeAsset);
    }
  }

  setActiveAsset(assetId) {
    this.setState({ activeAsset: assetId });
    this.setState({
      showSideBar: false,
    });
  }

  componentDidUpdate(nextProps, nextState) {
    if(this.state.playOriginal && !this.isPlayOriginalAvailable) {
      this.setState({
        playOriginal: false,
      });
    }
  }

  goIndex(index) {
    const id = this.props.assets.find((asset, assetIndex) => assetIndex === index).id;
    this.setActiveAsset(id);
  }

  togglePlayOriginal() {
    this.setState({
      playOriginal: !this.state.playOriginal,
    });
  }

  toggleSideBar() {
    this.setState({
      showSideBar: !this.state.showSideBar,
    });
  }

  get currentIndex() {
    return this.props.assets.findIndex(assets => assets.id === this.state.activeAsset);
  }

  get nextIndex() {
    return this.currentIndex === this.props.assets.length - 1 ? 0 : this.currentIndex + 1;
  }

  get prevIndex() {
    return this.currentIndex === 0 ? this.props.assets.length - 1 : this.currentIndex - 1;
  }

  get isPlayOriginalAvailable() {
    return this.activeAsset && this.activeAsset.metadata.assetType === 'mp4';
  }

  next() {
    this.goIndex(this.nextIndex);
  }

  handlePlayerError(error) {
    console.log(error);
  }

  render() {
    return (
      <div className='ems-play-all-container'>
        <Helmet>
          <style>
            {`
              html, body {
                overflow: hidden;
              }
              body {
                position: relative;
              }
              .ems-wrapper {
                overflow: hidden;
              }
            `}
          </style>
        </Helmet>
        <div className='ems-play-all-content'>
          <div className='ems-play-all-player'>
            {this.activeAsset && !this.state.playOriginal && <div className='ems-play-all-player-preview'><Player onError={error => this.handlePlayerError(error)} token={this.props.token} share={this.props.share} playing={true} onEnded={() => { this.next() }} previewUri={this.previewFile} asset={this.activeAsset} /></div>}
            {this.activeAsset && this.state.playOriginal && <div className='ems-play-all-player-original'><Player onError={error => this.handlePlayerError(error)} token={this.props.token} share={this.props.share} playing={true} onEnded={() => { this.next() }} previewUri={this.originalFile} asset={this.activeAsset} /></div>}
          </div>

          <div className='ems-play-all-controls'>
            <button className='ems-button ems-button-tostart ems-play-all-control-button' onClick={() => this.goIndex(0)}><i className='fas fa-angle-double-left'></i></button>
            <button className='ems-button ems-button-previous ems-play-all-control-button' onClick={() => this.goIndex(this.prevIndex)}><i className='fas fa-angle-left'></i></button>
            <button className='ems-button ems-button-next ems-play-all-control-button' onClick={() => this.goIndex(this.nextIndex)}><i className='fas fa-angle-right'></i></button>
            <button className='ems-button ems-button-tostart ems-play-all-control-button' onClick={() => this.goIndex(this.props.assets.length - 1)}><i className='fas fa-angle-double-right'></i></button>
            {this.isPlayOriginalAvailable && <button className={`ems-button ems-button-play-type ems-play-all-control-button ${this.state.playOriginal ? 'ems-button-play-type-preview' : 'ems-button-play-type-original'}`} onClick={() => this.togglePlayOriginal()}>
              <i className='fas fa-film'></i>
              <span className='ems-button-play-type-text'>{this.state.playOriginal ? 'Play Preview' : 'Play High Res Original'}</span>
            </button>}
          </div>
        </div>
        <button className='ems-button ems-play-all-close-mobile' onClick={() => this.props.close()}><i className='fas fa-times'></i></button>
        <button className='ems-button ems-play-all-sidebar-toggle' onClick={() => this.toggleSideBar()}>
          {this.state.showSideBar ? <i className='fas fa-angle-right'></i> : <i className='fas fa-angle-left'></i>}
          <span className='ems-play-all-sidebar-toggle-text'>{this.state.showSideBar ? 'Hide' : 'Show'} Playlist</span>
        </button>
        <div className={`ems-play-all-sidebar ${this.state.showSideBar ? 'ems-play-all-sidebar-mobile-show' : 'ems-play-all-sidebar-mobile-hidden'}`}>
          <div className='ems-heading-wrapper ems-play-all-sidebar-playlist-header'>
            <div className='ems-heading'>Results Playlist</div>
            <button className='ems-button ems-button-icon ems-button-close ems-play-all-close-button' onClick={() => this.props.close()}><i className='fas fa-times'></i></button>
          </div>
          <div className='ems-play-all-sidebar-playlist-container'>
            <div className='ems-play-all-sidebar-playlist' >
              {this.props.assets.map(asset => <div onClick={() => { this.setActiveAsset(asset.id) } } className={`ems-play-all-sidebar-playlist-item ${this.activeAsset && asset.id === this.activeAsset.id ? 'ems-play-all-sidebar-playlist-item-active' : ''}`} key={asset.id} data-media-type={asset.metadata.assetDomain}>
                 <span className='ems-play-all-sidebar-playlist-item-type'><i className={asset.metadata.assetDomain == 'video' ? 'fas fa-film' : 'fas fa-volume-up'}></i></span><span className='ems-play-all-sidebar-playlist-item-text'>{asset.name}</span> 
              </div>)}
            </div>
          </div>
          
        </div>
      </div>
    );
  }
}

export default PlayAll;
