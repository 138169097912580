import React, { Component } from 'react';
import api from '../../configs/api';

class ArticleSectionContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      article: {},
      images: [],
      loading: false,
    };
  }

  componentDidMount() {
    if (this.props.articleSettings[this.props.placement]) {
      this.fetchData(this.props.articleSettings[this.props.placement]);
    }
  }

  reset() {
    this.setState({
      article: {},
      loading: false,
      images: [],
    });
  }

  fetchData(articleId) {
    this.reset();

    api.getArticleImages(articleId).then(images => {
      this.setState({ images });  

      api.getArticle(articleId).then(article => {
        this.setState({ article });
      })
    });
  }

  articleHTML() {
    const html = this.state.article.html.html;

    const htmlWithActualImages = html.replace(new RegExp(/(img\/([a-z\-_0-9\/\:\.]*)\.(jpg|jpeg|png|gif))/i, 'g'), (...args) => {
      const image = this.state.images.find(image => image.sceId == args[2]);
      if (image)
        return api.getPreviewURL(image.id);
      else
        return args[0];
    });

    return {__html: htmlWithActualImages};
  }

  articleCSS() {
    return this.state.article.html.css;
  }

  componentDidUpdate(prevProps) {
    if (this.props.articleSettings[this.props.placement] !== prevProps.articleSettings[prevProps.placement]) {
      this.fetchData(this.props.articleSettings[this.props.placement]);
    }
  }

  render() {
    return (
      <div className='ems-article-section-container'>
          {this.state.article.html && <div className='ems-article-wrapper' dangerouslySetInnerHTML={this.articleHTML()}></div>}
          {this.state.article.html && <style>{this.state.article.html.css}</style>}
      </div>
    );
  }
}

export default ArticleSectionContainer;
