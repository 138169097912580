import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./ConfirmBox.css";

class ConfirmBox extends Component {
  render() {
    return (
      <div className="ems-confirm-box">
        <div className="ems-confirm-box-content">{this.props.children}</div>
        <div className="ems-confirm-box-buttons">
          <button
            className="ems-pill ems-confirm-box-buttons-reject"
            onClick={() => this.props.onReject()}
          >
            Reject
          </button>
          <button
            className="ems-pill ems-confirm-box-buttons-accept"
            onClick={() => this.props.onAccept()}
          >
            Accept
          </button>
        </div>
      </div>
    );
  }
}

export default ConfirmBox;
