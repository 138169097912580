import React, { Component } from "react";
import api from "../../configs/api";
import './SpotifyPlayer.css';

class SpotifyPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spotifyIDs: [],
    };
  }

  async getSpotifyID(isrc) {
    try {
      const ids = await api.getSpotifyID(isrc);

      if (ids && ids.length > 0) {
        this.setState({
          spotifyIDs: ids,
        });
      }

    } catch (err) {
      console.error(err);
    }

    this.setState({
      loading: false
    });
  }

  async componentDidMount() {
    if (this.props.ISRC) {
      this.setState({
        loading: true,
      });
      const spotifyID = await this.getSpotifyID(this.props.ISRC);
    }
  }

  render() {
    return (
      <div className="ems-pdf-preview-container">
        {this.state.spotifyIDs.length > 0 ? <div>

          <iframe 
            htmlstyle="border-radius:12px" 
            src={`https://open.spotify.com/embed/track/${this.state.spotifyIDs[0]}?utm_source=generator&theme=0`}
            width="100%" 
            height="80" 
            frameBorder="0" 
            allowFullScreen="" 
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture">
          </iframe>

        </div> : this.state.loading ? <div>Loading...</div> : ''}
      </div>
    );
  }
}

export default SpotifyPlayer;
