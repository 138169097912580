import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import api from '../../../configs/api';

class MenuItem extends Component {
  render() {
    const { menuItem, basketItemCount, featureList, isAuthenticated } = this.props;

    if (menuItem.type === 'sitePage') {
      if (menuItem.to === 'basket' && featureList.basket) {
        return (
          <Link 
            className='ems-header-nav-item' 
            to={`/${menuItem.to}${this.props.token ? `?token=${this.props.token}` : ''}`}>
              {this.props.menuItem.name}<span className='ems-header-nav-item-value'>{basketItemCount}</span>
          </Link>
        );
      }

      if (menuItem.to === 'logout' && isAuthenticated) {
        return (
          <Link 
            className='ems-header-nav-item' 
            to={`/sign-out`}>
              {this.props.menuItem.name}
          </Link>
        );
      }

      if (menuItem.to === 'home') {
        return (
          <Link 
            className='ems-header-nav-item' 
            to={`/`}>
              {this.props.menuItem.name}
          </Link>
        );
      }

      if (menuItem.to === 'shares' && featureList['shares']) {
        return (
          <Link 
            className='ems-header-nav-item' 
            to={`/${menuItem.to}${this.props.token ? `/private/${this.props.token}` : ''}`}>
              {this.props.menuItem.name}
          </Link>
        );
      }

      if (featureList[menuItem.to]) {
        return <Link className='ems-header-nav-item' to={`/${menuItem.to}`}>
          {this.props.menuItem.name}
        </Link>;
      }

      return false;
    }

    if (menuItem.type === 'dynamic') {
      let to;

      if (menuItem.to == 'hierarchy') {
        to = `/hierarchies/${menuItem.hierarchyId}`;
      }

      if (menuItem.to == 'article') {
        to = `/article/${menuItem.articleId}`;
      }

      if (menuItem.to == 'url') {
        return <a className="ems-header-nav-item" href={menuItem.url}>{menuItem.name}</a>;
      }

      return <Link className='ems-header-nav-item' to={to}>{menuItem.name}</Link>;
    }

    if (menuItem.type === 'withToken') {
      if (isAuthenticated) {
        return <a className="ems-header-nav-item" href={`${menuItem.url}?access_token=${api.token}`}>{menuItem.name}</a>;
      } else {
        return false;
      }
    }
  }
}

export default MenuItem;
