import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import okta from "../../../../helpers/okta";
import cognito from "../../../../helpers/cognito";

const LoginForm = ({ onSubmit, requestStatus, uiStatus }) => {
  const {
    register,
    formState,
    handleSubmit,
  } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="ems-sign-in-form">
      {uiStatus.isLoggedOut && (
        <div className="ems-warning ems-sign-in-form-logged-out-warning">You have been logged out.</div>
      )}

      {okta.isEnabled() && (
        <a className="ems-okta-login-link" href={okta.constructURL()}>
          Sign in with OKTA
        </a>
      )}

      {cognito.isEnabled() && (
        <a className="ems-cognito-login-link" href={cognito.constructURL()}>
          Sign in with Cognito
        </a>
      )}

      <h2 className="ems-margin-bottom ems-sign-in-form-title">Sign in</h2>
      {requestStatus.isSuccess && (
        <p className="success ems-sign-in-form-success">
          Signed in successfully.
        </p>
      )}
      {requestStatus.isError && (
        <p className="error ems-sign-in-form-error">
          {requestStatus.errorMessage || "An unknown error occurred."}
        </p>
      )}

      <input
        type="text"
        disabled={requestStatus.isLoading || requestStatus.isSuccess}
        {...register("username", { required: "Username is required" })}
        placeholder="Username"
        className="ems-input ems-input-text ems-margin-bottom-half ems-sign-in-form-input"
      />
      {formState.errors.username?.message}

      <input
        type="password"
        disabled={requestStatus.isLoading || requestStatus.isSuccess}
        autoComplete="password"
        {...register("password", { required: "Password is required" })}
        placeholder="Password"
        className="ems-input ems-input-password ems-margin-bottom ems-sign-in-form-input"
      />
      {formState.errors.password?.message}

      <button
        disabled={requestStatus.isLoading || requestStatus.isSuccess}
        type="submit"
        className="ems-button ems-sign-in-form-button"
      >
        Sign in
      </button>
      <Link
        to="/reset-password"
        className="ems-margin-top-half ems-reset-password-link"
      >
        Reset Password
      </Link>
    </form>
  );
};

LoginForm.defaultProps = {
  onSubmit() {},
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func,
  requestStatus: PropTypes.shape({
    isSuccess: PropTypes.bool,
    isError: PropTypes.bool,
    isLoading: PropTypes.bool,
    errorMessage: PropTypes.string
  })
};

export default LoginForm;
