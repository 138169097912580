import React, { Component } from 'react';
import './Folders.css';
import qs from 'qs';
import { Link } from 'react-router-dom';
import SearchBar from '../../components/SearchBar/SearchBar';
import BreadCrumb from '../../components/BreadCrumb/BreadCrumb';
import FolderList from '../../components/FolderList/FolderList';

class Folders extends Component {
  handleSearch(keyword) {
    this.props.search({
      keyword: keyword,
      page: 0,
    });
  }

  get breadcrumb() {
    const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const basePath = this.props.activeShare.folders.filter(folder => !this.props.activeShare.folders.find(f => folder != f && folder.startsWith(f))).find(folder => queryParams.activeFolder.startsWith(folder));
    const restPath = queryParams.activeFolder.slice(basePath.length).split('/').filter(path => path);
    const breadcrumb = [basePath].concat(restPath);
    return breadcrumb.map((folder, index) => ({ id: breadcrumb.slice(0, index+1).join('/'), name: index === 0 ? folder.split('/')[folder.split('/').length-1] : folder, path: breadcrumb.slice(0, index+1).join('/')  }));
  }

  get folders() {
    return this.props.folders.map(folder => {
      const pathArray = folder.split('/');
      const folderName = pathArray.length > 0 ? pathArray[pathArray.length-1] : folder;
      return { id: folder, path: folder, name: folderName, thumbnail: null  }
    });
  }

  render() {
    const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const hideFolders = queryParams.hideFolders === 'true';

    return (
      <React.Fragment>
        {this.props.activeShare && this.props.activeShare.folders.length > 0 && queryParams.activeFolder && <BreadCrumb visibleDeepness={0} breadcrumb={this.breadcrumb} url={path => ({ pathname: this.props.match.url, search: qs.stringify({ ...queryParams, activeFolder: path }, { addQueryPrefix: true })})} collection={this.props.collection} />}
        {this.props.activeShare && <SearchBar
          placeholder={this.props.searchInputPlaceholder}
          buttonText={this.props.searchButtonText}
          keyword={queryParams.keyword}
          reset={() => this.props.reset()}
          search={query => this.handleSearch(query)}/>}
        {this.props.activeShare && this.props.activeShare.folders.length > 0 && <div className='ems-heading ems-folder-list-heading-wrapper'>
          <h2 className='ems-folder-list-heading'>Folders</h2>
          {this.props.folders.length > 0 && <Link className='ems-button ems-button-icon ems-folder-list-toggle' to={{ pathname: this.props.match.url, search: qs.stringify({ ...queryParams, hideFolders: !hideFolders }, { addQueryPrefix: true }) }}>
            {!hideFolders && <i className="far fa-minus-square"></i>}
            {hideFolders && <i className="far fa-plus-square"></i>}
          </Link>}
        </div>}
        {this.props.activeShare && this.props.activeShare.folders.length > 0 && <div className={`ems-margin-bottom ems-folder-list-wrapper ${hideFolders ? 'ems-folder-list-wrapper-hidden' : ''}`}>
          {this.folders.length > 0 && <FolderList folderList={this.folders} activeShare={this.props.activeShare} token={this.props.match.params.token} />}
          {this.props.ui.sharesLoadingSuccess && this.props.ui.browseShareLoadingSuccess && this.folders.length === 0 && <p className='ems-margin-top ems-margin-bottom ems-folder-list-message'>There are no subfolders to list.</p>}
          {this.props.ui.sharesLoading || this.props.ui.browseShareLoading && <p className='ems-folder-list-message'>Loading <i className="fas fa-circle-notch fa-spin"></i></p>}
        </div>}
      </React.Fragment>
    );
  }
}

export default Folders;