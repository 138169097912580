import React, { Component } from 'react';
import './HierarchyList.css';
import HierarchyListItem from './HierarchyListItem/HierarchyListItem';

class HierarchyList extends Component {
  render() {
    return (
      <div className='ems-margin-bottom ems-hierarchies-list'>
        {this.props.hierarchyList.map(hierarchy => <HierarchyListItem showPreviewAsThumbnail={this.props.showPreviewAsThumbnail} key={hierarchy.id} hierarchy={hierarchy}/>)}
      </div>
    );
  }
}

export default HierarchyList;
