import React, { Component } from 'react';
import './Zoom.css';

class Zoom extends Component {
  constructor() {
    super();

    this.state = {
      previewMode: 'fit-to-screen',
      imgSize: 'small',
      loading: true,
      rotation: 0,
    };
  }

  changePreviewMode(previewMode) {
    this.setState({
      previewMode
    });
  }

  changeImgSize(imgSize) {
    this.setState({
      imgSize,
      loading: true,
    });
  }

  changeImgRotation() {
    this.setState({
      rotation: this.state.rotation + 90,
    });
  }

  handleImageLoaded() {
    this.setState({
      loading: false,
    });
  }

  handlePreviewError(err) {
    console.error(err);
  }

  render() {
    return (
      <div className='ems-zoom-container'>
        <div className='ems-zoom-header'>
          <span>{this.props.title}</span>
        </div>

        <div className='ems-zoom-content'>
          <div className='ems-zoom-image-container'>
            {this.props.content ? <div className='ems-zoom-pdf-container'>{this.props.content}</div> : ''}
            
            {!this.props.content && this.state.loading && <p className='ems-zoom-image-container-img-loading'>Loading <i className="fas fa-circle-notch fa-spin"></i></p>}
            {!this.props.content && <img
              style={{transform: `rotate(${this.state.rotation}deg)`}}
              onLoad={() => this.handleImageLoaded()}
              alt='preview' 
              className={`ems-zoom-image-container-img-${this.state.previewMode}`} src={this.props.asset[this.state.imgSize].src}/>}
          </div>
        </div>

        <div className='ems-zoom-footer'>
          {!this.props.content && <div>
            <button 
              className={`ems-button ems-zoom-button ${this.state.imgSize=='small' ? 'ems-zoom-button-active active' : ''}`}
              onClick={e => { this.changeImgSize('small') }}>Small <sub>(size: {this.props.asset.small.size}x{this.props.asset.small.size})</sub></button>
            <button 
              className={`ems-button ems-zoom-button ${this.state.imgSize=='large' ? 'ems-zoom-button-active active' : ''}`}
              onClick={e => { this.changeImgSize('large') }}>Large <sub>(size: {this.props.asset.large.size}x{this.props.asset.large.size})</sub></button>
            {this.props.asset.original.size > this.props.asset.large.size && <button 
              className={`ems-button ems-zoom-button ${this.state.imgSize=='original' ? 'ems-zoom-button-active active' : ''}`}
              onClick={e => { this.changeImgSize('original') }}>Original <sub>(size: {this.props.asset.original.size}x{this.props.asset.original.size})</sub></button>}
            {this.props.asset.max && <button 
              className={`ems-button ems-zoom-button ${this.state.imgSize=='max' ? 'ems-zoom-button-active active' : ''}`}
              onClick={e => { this.changeImgSize('max') }}>Max <sub>(size: {this.props.asset.max.size}x{this.props.asset.max.size})</sub></button>}
          </div>}
          {!this.props.content &&  <div>
            <button 
              className='ems-button ems-zoom-button'
              onClick={e => { this.changeImgRotation() }}>Rotate</button>
            <button 
              className={`ems-button ems-zoom-button ${this.state.previewMode=='full-size' ? 'ems-zoom-button-active active' : ''}`}
              onClick={e => { this.changePreviewMode('full-size') }}>Full Size</button>
            <button 
              className={`ems-button ems-zoom-button ${this.state.previewMode=='fit-to-width' ? 'ems-zoom-button-active active' : ''}`}
              onClick={e => { this.changePreviewMode('fit-to-width') }}>Fit to Width</button>
            <button 
              className={`ems-button ems-zoom-button ${this.state.previewMode=='fit-to-screen' ? 'ems-zoom-button-active active' : ''}`}
              onClick={e => { this.changePreviewMode('fit-to-screen') }}>Fit to Screen</button>
          </div>}
          <div>
            <button className='ems-zoom-button' onClick={() => { this.props.close(); }}>Close</button>
          </div>
        </div>
      </div>
    );
  }
}

export default Zoom;
