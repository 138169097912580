import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './HierarchyListItem.css';
import Thumbnail from '../../Thumbnail/Thumbnail';
import api from '../../../configs/api';

class HierarchyListItem extends Component {
  render() {
    return (
      <Link to={`/hierarchies/${this.props.hierarchy.path}`} className={this.props.hierarchy.thumbnail ? 'ems-item ems-hierarchies-list-item' : 'ems-item ems-hierarchies-list-item ems-hierarchies-list-item-no-thumbnail'}>
        <div className='ems-item-inner ems-hierarchies-list-item-inner'>
          <div className='ems-thumbnail ems-hierarchies-list-item-thumbnail'>
            <Thumbnail
              type='hierarchy'
              className='ems-thumbnail-inner ems-hierarchies-list-item-thumbnail-inner'
              src={this.props.showPreviewAsThumbnail ? api.getPreviewURL(this.props.hierarchy.thumbnailAsset) : this.props.hierarchy.thumbnail}/>
          </div>
          <div className='ems-details ems-hierarchies-list-item-details'>
            <div className='ems-details-inner ems-hierarchies-list-item-details-inner'>
              <div className='ems-h4 ems-title ems-hierarchies-list-item-title'>{this.props.hierarchy.name}</div>
            </div>
          </div>
        </div>  
      </Link>
    );
  }
}

export default HierarchyListItem;
