import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import qs from 'qs';
import './Pagination.css';

class Pagination extends Component {
  get totalPageCount() {
    return Math.ceil(this.props.totalItem / this.props.itemPerPage);
  }

  get pageList() {
    return [...Array(this.totalPageCount).keys()];
  }

  get visibleRange() {
    const PAGINATION_LIST_RANGE = 9;

    const padding = Math.floor(PAGINATION_LIST_RANGE / 2);

    const startingPage = this.totalPageCount > Math.ceil(PAGINATION_LIST_RANGE / 2) ? 
      this.props.activePage + padding > this.totalPageCount ?
        this.totalPageCount - PAGINATION_LIST_RANGE < 0 ? PAGINATION_LIST_RANGE - this.totalPageCount : this.totalPageCount - PAGINATION_LIST_RANGE :
          (
            this.totalPageCount > PAGINATION_LIST_RANGE && this.props.activePage > padding ? 
              ((this.props.activePage - padding) > 0 ? (this.props.activePage - padding) : 0) : 
              0
          ) :
      0;
    
    return this.pageList.slice(
      startingPage,
      startingPage + PAGINATION_LIST_RANGE
    );
  }

  get prevPage() {
    return this.props.activePage - 1 > 0 ? this.props.activePage - 1 : 0;
  }

  get nextPage() {
    return this.props.activePage + 1 === this.totalPageCount ? this.totalPageCount - 1 : this.props.activePage + 1;
  }

  get lastPage() {
    return this.totalPageCount - 1;
  }

  getBaseURL(page) {
    const queryObj = qs.parse(this.props.history.location.search, { ignoreQueryPrefix: true });

    const newQueryObj = {
      ...queryObj,
      page
    }

    return { 
      pathname: this.props.history.location.pathname, 
      search: qs.stringify(newQueryObj, { addQueryPrefix: true }) 
    };
  }

  render() {
    return (
      <div className='ems-margin-top ems-pagination-wrapper'>
        <div className='ems-pagination-list'>
          <div className="ems-pagination-list-buttons">
            <Link to={this.getBaseURL(0)} title='Jump to first page' className='ems-button ems-pagination-list-button'><i className='fas fa-angle-double-left'></i></Link>          
            <Link to={this.getBaseURL(this.prevPage)} title='Previous page' className='ems-button ems-pagination-list-button'><i className='fas fa-angle-left'></i></Link>
          </div>
          <div className="ems-pagination-list-pages">
          {this.visibleRange.map(page => 
            <Link 
              to={this.getBaseURL(page)}
              className={`ems-pagination-list-item ${this.props.activePage === page ? 'ems-pagination-list-item-active' : ''}`}
              key={page}>
              {page + 1}
            </Link>)}
          </div>
          <div className="ems-pagination-list-buttons">
            <Link to={this.getBaseURL(this.nextPage)} title='Next page' className='ems-button ems-pagination-list-button'><i className='fas fa-angle-right'></i></Link>
            <Link to={this.getBaseURL(this.lastPage)} title='Jump to last page' className='ems-button ems-pagination-list-button'><i className='fas fa-angle-double-right'></i></Link>            
          </div>
        </div>
        <div className='ems-pagination-select-container'>
        <span className='ems-pagination-select-title'>Jump to page</span><input 
            className='ems-pagination-select' 
            type='number' 
            min='1' 
            max={this.pageList.length}
            defaultValue={this.props.activePage + 1}
            onKeyPress={e => { e.key === 'Enter' && !isNaN(parseInt(e.target.value, 10)) && this.props.history.push(this.getBaseURL(e.target.value - 1)) }}/>
            <span className='ems-pagination-select-total-pages'><span className='ems-pagination-select-total-pages-word'>of</span>{this.pageList.length}</span>
        </div>
      </div>
    );
  }
}

export default withRouter(Pagination);