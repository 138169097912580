import React, { Component } from 'react';
import './ContributeForm.css';

class ContributeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {

      },
    };
  }

  handleChange(input, value) {
    this.setState({
      values: {
        ...this.state.values,
        [input.metadata]: value,
      }
    })
  }
  
  getValueOf(metadata) {
    return this.state.values[metadata] || '';
  }

  renderInput(input) {
    return (
      <div key={input.name} className='ems-contribute-form-field'>
        <label className='ems-contribute-form-field-label'>
          {input.name} <span className='ems-contribute-form-field-label-required'>{input.required && '(Required)'}</span>
        </label>
        <input
          className='ems-contribute-form-field-input'
          type={input.type}
          required={input.required}
          placeholder={input.name}
          value={this.getValueOf(input.metadata)}
          onChange={e => this.handleChange(input, e.target.value)}
        />
      </div>
    );
  }

  renderSelect(input) {
    const selectedValue = this.getValueOf(input.metadata);

    return (
      <div key={input.name} className='ems-contribute-form-field'>
        <label className='ems-contribute-form-field-label' htmlFor={input.name}>
          {input.name} <span className='ems-contribute-form-field-label-required'>{input.required && '(Required)'}</span>
        </label>
        <select
          name={input.name}
          id={input.name}
          className='ems-contribute-form-field-input'
          type={input.type}
          required={input.required}
          placeholder={input.name}
          value={this.getValueOf(input.metadata)}
          onChange={e => this.handleChange(input, e.target.value)}
        >
          <option value=''>-</option>
          {input.options.map(option => <option key={option.value} value={option.value}>{option.name}</option>)}
        </select>
      </div>
    );
  }

  get isSubmitAllowed() {
    const requiredFields = this.props.inputList.filter(input => input.required);
    return requiredFields.every(requiredField => !!this.state.values[requiredField.metadata]);
  }

  submitForm(e) {
    e.preventDefault();
    if (!this.isSubmitAllowed) {
      alert('Please fill in all the required fields.');
      return;
    }

    this.props.onSubmit(this.state.values);
  }

  render() {
    return (
      <form className='ems-contribute-form' onSubmit={this.submitForm.bind(this)}>
        {this.props.inputList.map(input => input.type!=='select' ? this.renderInput(input) : this.renderSelect(input))}
        {this.isSubmitAllowed && <button className='ems-button ems-contribute-form-button'>Submit</button>}
      </form>
    );
  }
}

export default ContributeForm;
