import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Login from "./components/Login";
import ResetPassword from "./components/ResetPassword";
import ShareLogin from "./components/ShareLogin";
import Logout from "./components/Logout";
import { useSiteSettings } from "../../hooks/useSiteSetting";

const AuthPage = () => {
  const siteSettings = useSiteSettings();

  const generalSiteSettings = siteSettings?.data?.general;

  // TODO: better loading indicator
  if (siteSettings.isLoading) {
    return <div className='ems-loading-indicator'>Loading...</div>;
  }

  return (
    <div className="ems-container">
      <Header settings={generalSiteSettings} isAuthenticated={false} />

      <Switch>
        <Route exact path="/auth">
          <Login useProxy={generalSiteSettings?.useElvisProxyForAll} />
        </Route>
        <Route path="/auth/logout">
          <Logout />
        </Route>
        <Route path="/auth/reset">
          <ResetPassword resetPasswordURLforNonStrapiUser={generalSiteSettings.resetPasswordURLforNonStrapiUser} />
        </Route>
        <Route path="/auth/share/:token">
          <ShareLogin />
        </Route>
      </Switch>

      <Footer settings={generalSiteSettings} />
    </div>
  );
};

export default AuthPage;
