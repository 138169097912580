import React, { Component } from 'react';
import './Footer.css';

class Footer extends Component {
  render() {
    return (
      <footer className='ems-footer' dangerouslySetInnerHTML={{__html: this.props.settings ? this.props.settings.footerHTML : ''}}>
      </footer>
    );
  }
}

export default Footer;
