import cookie from 'cookie';

const cookies = {
  set(name, value, options) {
    return document.cookie = cookie.serialize(name, value, options)
  },
  get(name) {
    return cookie.parse(document.cookie)[name]
  },
  remove(name) {
    return document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`
  }
}

export default cookies;
