import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";

const ShareLoginForm = ({ onSubmit, requestStatus }) => {
  const { register, formState, handleSubmit } = useForm();

  return (
    <form
      className="ems-sign-in-form ems-sign-in-to-share-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <h2 className="ems-margin-bottom ems-sign-in-form-title ems-sign-in-to-share-form-title">
        Private Share
      </h2>
      {requestStatus.isSuccess && (
        <p className="success sign-in-to-share-form-success">
          Signed in successfully.
        </p>
      )}


      {/* TODO: BETTER ERROR HANDLING */}
      {requestStatus.isError && (
        <p className="error sign-in-to-share-form-error">
          {requestStatus.error?.status === 403 ||
          requestStatus.error?.status === 404 ? (
            <>
              {requestStatus.error?.status === 404 &&
                "Couldn't find the share."}
              {requestStatus.error?.status === 403 &&
                "Password is not correct."}
            </>
          ) : (
            `Something went wrong: ${
              requestStatus.error?.message ||
              requestStatus.error?.body?.message ||
              "An unknown error occurred."
            }`
          )}
        </p>
      )}

      <input
        type="password"
        {...register("sharePassword", { required: "Password is required" })}
        className="ems-input ems-input-password ems-margin-bottom ems-sign-in-form-input ems-sign-in-to-share-form-input"
        placeholder="Password"
      />
      {formState.errors.sharePassword?.message}

      <button
        disabled={requestStatus.isLoading || requestStatus.isSuccess}
        type="submit"
        className="ems-button ems-sign-in-form-button sign-in-to-share-form-button"
      >
        Submit
      </button>
    </form>
  );
};

ShareLoginForm.defaultProps = {
  onSubmit() {},
};

ShareLoginForm.propTypes = {
  onSubmit: PropTypes.func,
  requestStatus: PropTypes.shape({
    isSuccess: PropTypes.bool,
    isError: PropTypes.bool,
    isLoading: PropTypes.bool,
    error: PropTypes.object,
  }),
};

export default ShareLoginForm;
