import React, { Component } from 'react';
import './FolderList.css';
import FolderListItem from './FolderListItem/FolderListItem';

class FolderList extends Component {
  render() {
    return (

        <div className='ems-folder-list'>
          {this.props.folderList.map(folder => <FolderListItem key={folder.id} folder={folder} activeShare={this.props.activeShare} token={this.props.token}/>)}
        </div>

    );
  }
}

export default FolderList;
