import api from '../../../configs/api';
import elvisApi from '../../../helpers/elvisApi';

export default class HierarchyService {
  constructor(useElvisProxyForAll = false) {
    this.requestApi = useElvisProxyForAll ? api : elvisApi;
  }

  async loadAssets(query) {
    const filters = {};

    Object.keys(query.filters).forEach(filterName => {
      filters[filterName] = query.filters[filterName].join(',');
    });

    const result = await this.requestApi.search({
      q: query.query,
      facets: query.facets,
      sort: query.sortBy,
      num: query.num,
      start: query.start,
      ...filters
    });

    return {
      assets: result.hits.map(hit => ({
        id: hit.id,
        name: hit.metadata.name,
        thumbnail: hit.thumbnailUrl ? api.getThumbnailURL(hit.id) : hit.thumbnailHits && hit.thumbnailHits.length > 0 ? api.getThumbnailURL(hit.thumbnailHits[0].id) : null,
        metadata: hit.metadata
      })),
      facets: result.facets,
      totalHits: result.totalHits,
    };
  }

  async getCollection(collectionId) {
    const { hits } = await this.requestApi.search({ q: `id: ${collectionId}` });
    const collection = hits[0];
    return collection;
  }
}
