import { useMutation } from "react-query";
import api from "../../../../configs/api";

export const useResetPasswordRequest = () => {
  return useMutation((email) => api.forgotPassword(email));
};

export const useSetNewPassword = () => {
  return useMutation(({code, password, passwordConfirmation}) =>
    api.resetPassword(code, password, passwordConfirmation)
  );
};
