import React, { Component } from 'react'
import api from '../configs/api';
import elvisApi from '../helpers/elvisApi';
import settingsValidator, { logMissingSettings } from '../helpers/settingsValidator/settingsValidator';

const AppStateContext = React.createContext();

export const AppStateConsumer = AppStateContext.Consumer;

class AppStateProvider extends Component {
  state = {
    isHierarchiesActive: false,
    isSharesActive: false,
    isArticlesActive: false,
    isContributeActive: false,
    isBasketActive: false,
    siteLayout: "vertical",
    previewSettings: {},
    settings: [],
    autoLogin: false,

    settingsLoading: false,
    settingsLoadingSuccess: false,

    menus: [],

    changeAppState: (item, value) => this.change(item, value)
  };

  componentDidMount () {
    this.setState({
      settingsLoading: true,
    });

    api.getSettings().then(settings => {
      logMissingSettings(settingsValidator(settings));

      api.getMenus().then(menus => {
        const generalSettings = settings.find(({ name }) => name === 'general').config;
        const previewSettings = settings.find(({ name }) => name === 'preview').config;

        this.setState({
          isHierarchiesActive: generalSettings.featureList.hierarchies,
          autoLogin: generalSettings.publicPortal,
          isSharesActive: generalSettings.featureList.shares,
          isArticlesActive: generalSettings.featureList.articles,
          isContributeActive: generalSettings.featureList.contribute,
          isBasketActive: generalSettings.featureList.basket,
          siteLayout: generalSettings.siteLayout,
          previewSettings,
          settings,
          menus,

          settingsLoading: false,
          settingsLoadingSuccess: true,
        });

      });


    });
  }

  change(item, value) {
    this.setState({
      [item]: value,
    });
  }

  render() {
    return (
      <AppStateContext.Provider value={this.state}>
        {this.props.children}
      </AppStateContext.Provider>
    );
  }
};

export default AppStateProvider;