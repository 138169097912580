import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useResetPasswordRequest } from "./hooks";
import api from "../../../../configs/api";

const ResetPasswordRequest = (props) => {
  const {
    register,
    watch,
    formState: { isValid },
    handleSubmit,
  } = useForm({ mode: "onChange" });

  const email = watch("email");
  const resetPasswordMutation = useResetPasswordRequest();

  const userTypeRequest = useQuery(
    ["userType", email],
    () => api.getUserType(email),
    {
      enabled: isValid && !!email,
      retry: false
    }
  );

  const onSubmit = async (data) => {
    resetPasswordMutation.mutate(data.email);
  };

  return (
    <form className="ems-reset-password-form" onSubmit={handleSubmit(onSubmit)}>
      {!resetPasswordMutation.isSuccess && (
        <React.Fragment>
          <h2 className="ems-margin-bottom ems-reset-password-form-title">
            Change Password
          </h2>
          {(userTypeRequest.isError || resetPasswordMutation.isError) && (
              <p className="error ems-reset-password-form-error">
                {userTypeRequest.isError && <>
                  {userTypeRequest.error?.status === 404 && "There is no user registered with this email address."}
                  {(userTypeRequest.error?.status !== 404) && (userTypeRequest.error?.message || "An unknown error occurred.")}
                </>}
                {(!userTypeRequest.isError && resetPasswordMutation.isError) && <>
                  {resetPasswordMutation.error?.message || "An unknown error occurred."}
                </>}
              </p>
            )}
          {userTypeRequest.data?.type === "elvis" && (
            <>
              <p className="error ems-reset-password-form-error">
                You can"t change password of an Elvis account from here.
              </p>
              <p>
                Please try changing your password from this url:{" "}
                {
                  <a href={props.resetPasswordURLforNonStrapiUser}>
                    {props.resetPasswordURLforNonStrapiUser}
                  </a>
                }
              </p>
            </>
          )}
          <input
            type="text"
            {...register("email", {
              required: "Email is required",
              pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            })}
            className="ems-input ems-input-text ems-margin-bottom-half ems-reset-password-form-input"
            placeholder="Email"
          />
          <button
            disabled={
              !isValid ||
              userTypeRequest.isLoading ||
              resetPasswordMutation.isLoading ||
              userTypeRequest.data?.type === "elvis"
            }
            type="submit"
            className="ems-button ems-reset-password-form-button"
          >
            {userTypeRequest.isLoading || resetPasswordMutation.isLoading
              ? "Loading..."
              : "Submit"}
          </button>
        </React.Fragment>
      )}
      {resetPasswordMutation.isSuccess && (
        <p>An email has been sent to: {email}</p>
      )}
    </form>
  );
};

ResetPasswordRequest.defaultProps = {};

ResetPasswordRequest.propTypes = {
  resetPasswordURLforNonStrapiUser: PropTypes.string.isRequired,
};

export default ResetPasswordRequest;
