import configs from '../configs/config';

export default {
  isEnabled: () => (
    !!configs['cognito.url'] && !!configs['cognito.clientId']
  ),

  constructURL: () => (
    encodeURI(
      `${configs['cognito.url']}/oauth2/authorize?client_id=${configs['cognito.clientId']}` +
      `&response_type=code` +
      `&scope=email+openid+profile` +
      `&redirect_uri=${configs['branding.url']}/auth/cognito`
    )
  )
}
