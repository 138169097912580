import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import api from '../../../configs/api';
import elvisApi from '../../../helpers/elvisApi';
import AssetListItem from '../AssetListItem/AssetListItem';
import './AssetListCollectionItem.css';
import Carousel, { consts } from 'react-elastic-carousel';

class AssetListCollectionItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      result: {
        assets: [],
        facets: [],
        totalHits: 0
      },
      ui: {
        collectionLoading: false,
        collectionLoadingSuccess: false,
      }
    };
  }

  componentDidMount() {
    this.loadCollection(this.props.id);
  }

  async loadCollection(collectionId) {
    this.setState({
      ui: {
        ...this.state.ui,
        collectionLoading: true,
        collectionLoadingSuccess: false,
      }
    });

    try {
      const requestApi = this.props.settings.general.useElvisProxyForAll ? api : elvisApi;
      const result = await requestApi.search({ q: `relatedTo:${collectionId} relationTarget:child relationType:contains` });

      this.setState({
        ui: {
          ...this.state.ui,
          collectionLoading: false,
          collectionLoadingSuccess: true,
        },
        result: {
          ...this.state.result,
          items: result.hits.map(hit => ({
            id: hit.id,
            name: hit.metadata.name,
            thumbnail: hit.thumbnailUrl ? api.getThumbnailURL(hit.id) : hit.thumbnailHits && hit.thumbnailHits.length > 0 ? api.getThumbnailURL(hit.thumbnailHits[0].id) : null,
            metadata: hit.metadata
          })),
          facets: result.facets,
          totalHits: result.totalHits,
        }
      });
    } catch(error) {
      console.log(error);      
    }
  }

  get breakpoints() {
    const { breakpoints } = this.props.settings.style.responsive;
    const { numbers } = this.props.settings.style.assets;
    return Object.keys(breakpoints).map(breakpoint => ({ 
      width: parseInt(breakpoints[breakpoint], 10) - ((this.props.settings.style.responsive.spacings.generalPadding/4 || 7) * 2) - ((this.props.settings.style.responsive.spacings.generalPadding || 30) * 2) - 40, 
      itemsToShow: numbers[breakpoint] ? parseInt(numbers[breakpoint], 10) : 1,
      itemsToScroll: numbers[breakpoint] ? parseInt(numbers[breakpoint], 10) : 1,
    }))
    .concat([{ width: 1, itemsToShow: 1, itemsToScroll: 1 }])
    .sort((a, b) => a.width - b.width);
  }

  arrows({ type, onClick, isEdge }) {
    return (
      <button 
        onClick={onClick}
        disabled={isEdge}
        className={`ems-button ems-button-icon ${type === consts.PREV ? 'ems-asset-list-collection-carousel-back-button' : 'ems-asset-list-collection-carousel-next-button'}`}>
          <i className={`fas ${type === consts.PREV ? 'fa-chevron-left' : 'fa-chevron-right'}`}></i>
      </button>
    );
  }

  render() {
    const collectionQueryURL = this.props.location.pathname + (
      this.props.assetQueryParams ? 
        this.props.assetQueryParams + `&viewCollection=${this.props.collection.id}` : 
        `?viewCollection=${this.props.collection.id}`
    );

    return (
      <div className='ems-asset-list-collection-item'>
        <div className='ems-asset-list-collection-item-inner'>
          <div className='ems-title ems-asset-list-collection-item-title'>{this.props.collection.name} <span className='ems-asset-list-collection-item-count'>{this.state.result.totalHits}</span></div>
          {this.state.ui.collectionLoadingSuccess && this.state.result.items.length === 0 && !this.state.ui.collectionLoading && <p className='ems-margin-top ems-margin-bottom ems-message ems-asset-list-collection-item-message'>There are no items to list.</p>}
          {this.state.ui.collectionLoading && <p className='ems-margin-top ems-margin-bottom ems-message ems-asset-list-collection-item-message'>Loading <i className="fas fa-circle-notch fa-spin"></i></p>}

          {!this.state.ui.collectionLoading && this.state.ui.collectionLoadingSuccess && this.state.result.items.length > 0 && <div className='ems-asset-list-collection-item-list'>
              <div className='ems-asset-list-collection-carousel-wrapper'>
                <Carousel
                  breakPoints={this.breakpoints}
                  pagination={false}
                  renderArrow={this.arrows}>
                  {this.state.result.items.map((asset, index) => 
                    <AssetListItem
                      index={index}
                      token={this.props.token}
                      activeShare={this.props.activeShare}
                      type={this.props.type}
                      assetQueryParams={this.props.assetQueryParams}
                      collectionInURLQuery={this.props.collectionInURLQuery}
                      assetMetadataList={this.props.settings.assetMetadataList}
                      key={asset.id}
                      asset={asset}
                      toggleBasket={this.props.toggleBasket}
                      addedToBasket={this.props.basketList.includes(asset.id)}
                    />
                  )}
                </Carousel>
              </div>
            <Link to={collectionQueryURL} className='ems-button ems-button-icon ems-asset-list-collection-carousel-click-more'>View collection</Link>          
          </div>}
        </div>
      </div>
    );
  }
}

export default withRouter(AssetListCollectionItem);
