import { useQuery } from "react-query";
import api from "../configs/api";

export const useSiteSettings = () =>
  useQuery(["siteSettings"], () => api.getSettings(), {
    select: (settings) =>
      Object.fromEntries(
        settings.map((setting) => [setting.name, setting.config])
      ),
    staleTime: Infinity
  });
