import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { AppStateConsumer } from '../../providers/AppState';
import MenuItem from './MenuItem/MenuItem';
import './Header.css';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navOpen: false,
    };
  }

  get basketItemCount() {
    const hierarchies = localStorage.hierarchiesBasket ? JSON.parse(localStorage.hierarchiesBasket) : [];
    const shares = localStorage.sharesBasket ? JSON.parse(localStorage.sharesBasket) : [];

    if (this.props.featureList.hierarchies && this.props.featureList.shares) {
      return hierarchies.length + shares.length;
    }
    
    if (this.props.featureList.hierarchies) {
      return hierarchies.length;
    }

    return shares.length;
  }

  toggleNav() {
    this.setState({
      navOpen: !this.state.navOpen
    });
  }

  render() {
    const menuClass = this.props.siteLayout === 'horizontal' ? 'ems-header-nav--horizontal' : 'ems-header-nav--vertical';

    return (
      <header className={`ems-header ems-padding-horizontal ${this.state.navOpen ? 'ems-header-nav-open' : 'ems-header-nav-closed'} ${this.props.siteLayout === 'horizontal' ? 'ems-header--horizontal' : 'ems-header--vertical'}`}>
        <Link className='ems-header-logo' to='/'>
          {this.props.settings && this.props.settings.siteLogo && <img className='ems-header-site-logo' alt={this.props.settings && this.props.settings.siteName} src={this.props.settings.siteLogo}/>}
          {this.props.settings && this.props.settings.siteName}
        </Link>
        <div className='ems-header-nav-wrapper'>

        {this.props.isAuthenticated && this.props.menus.sort((menuA, menuB) => menuA.order - menuB.order).map(menu => 
          <div className={`ems-header-nav  ${menuClass}`} key={menu.id}>
            {menu.title && <p className='ems-heading-title'>{menu.title}</p>}
            {menu.menuItems.filter(menuItem => !(this.props.autoLogin && menuItem.to === 'logout')).map(menuItem =>
              <MenuItem
                token={this.props.token}
                key={menuItem.name}
                isAuthenticated={this.props.isAuthenticated}
                featureList={this.props.featureList}
                menuItem={menuItem}
                basketItemCount={this.basketItemCount}/>
            )}
          </div>
        )}
        </div>
        <div className="ems-header-nav-mobile">
          <div className="ems-header-nav-item">
            <i className="fas fa-bars" onClick={() => this.toggleNav()}></i> {/* Toggle Class "header-nav-open" on .header */}
          </div>
        </div>
        
      </header>
    );
  }
}

const ConnectedHeader = props => (
  <AppStateConsumer>
    {({ isHierarchiesActive, isSharesActive, isArticlesActive, isBasketActive, isContributeActive, siteLayout, menus, autoLogin }) => (
      <Header
        {...props}
        menus={menus}
        autoLogin={autoLogin}
        featureList={
          {
            hierarchies: isHierarchiesActive,
            shares: isSharesActive,
            articles: isArticlesActive,
            basket: isBasketActive,
            contribute: isContributeActive
          }
        }
        siteLayout={siteLayout}
      />
    )}
  </AppStateConsumer>
);

export default ConnectedHeader;
