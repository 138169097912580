import React from "react";
import {
  useLocation,
  useParams,
  Redirect,
  useRouteMatch,
} from "react-router-dom";
import qs from "qs";

const parseQS = (query) => qs.parse(query, { ignoreQueryPrefix: true });

const redirections = {
  "/sign-in": () => "/auth",
  "/sign-out": () => "/auth/logout",
  "/sign-in-to-share/:token": ({ params }) => `/auth/share/${params.token}`,
  "/reset-password": ({ location: { search } }) =>
    search ? `/auth/reset/${parseQS(search)?.code}` : `/auth/reset`,
};

const Redicretions = (props) => {
  const location = useLocation();
  const params = useParams();
  const match = useRouteMatch();

  const to = redirections[match.path]({ params, location });

  return (
    <Redirect
      to={{
        pathname: to,
        state: { from: location },
      }}
    />
  );
};

export const RedirectedPaths = Object.keys(redirections);

export default Redicretions;
