import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import api from '../../../configs/api';
import msToTime from '../../../helpers/msToTime';
import './AssetListItem.css';
import GoogleAnalytics from 'react-ga';
import Thumbnail from '../../Thumbnail/Thumbnail';

class AssetListItem extends Component {
  handleToggleBasket(e) {
    e.preventDefault();
    this.props.toggleBasket(this.props.asset.id);
  }

  get downloadURL() {
    if (this.props.token) {
      return api.getPrivateDownloadURL(this.props.token);
    } else if (this.props.activeShare) {
      return api.getSharedDownloadURL(this.props.activeShare);
    } else {
      return api.getDownloadURL();
    }
  }

  downloadCollection() {
    window.open(`${this.downloadURL}?q=parentContainerIds:${this.props.asset.id}`)
  }

  get fileURL() {
    if (this.props.token) {
      return api.getPrivateFileURL(this.props.asset.id, this.props.token);
    } else if (this.props.activeShare) {
      return api.getSharedFileURL(this.props.activeShare, this.props.asset.id);
    } else {
      return api.getFileURL(this.props.asset.id);
    }
  }

  downloadAsset() {
    window.open(`${this.fileURL}/${this.props.asset.name}?forceDownload=true`)
  }

  download(e) {
    e.preventDefault();
    GoogleAnalytics.event({
      category: 'Download',
      action: `Downloaded an Asset from a ${this.props.type} asset list item`,
      label: `asset id:${this.props.asset.id} | date:${(new Date()).toDateString()}`,
    });

    if (this.props.asset.metadata.assetType === 'collection') {
      this.downloadCollection();
    } else {
      this.downloadAsset();
    }
  }

  renderMetadataValue(metadata) {
    const value = Array.isArray(metadata) ? 
      metadata.map((value, index)  => <span key={index} className='ems-item-view-content-details-metadata-item-value-mutlivalue'>{value}</span>) : 
          typeof metadata === 'object' ? metadata.formatted : metadata;
    
    return value;
  }

  get isStream() {
    return (
      this.props.activeShare &&
      this.props.activeShare.stream &&
      Object.keys(this.props.activeShare.stream).filter(metadata => this.props.activeShare.stream[metadata].includes(this.props.asset.metadata[metadata])).length
    )
  }
  
  get itemPath() {
    if (this.props.activeShare)
      return `share-item/${this.props.activeShare.id}`;
    if (this.props.type == 'share')
      return 'share-item';
    if (this.props.type == 'hierarchy')
      return 'item'; 
  }

  render() {
    const collectionQueryURL = this.props.location.pathname + (
        this.props.assetQueryParams ? 
          this.props.assetQueryParams + `&viewCollection=${this.props.asset.id}` : 
          `?viewCollection=${this.props.asset.id}`
      );
    
    const to = (this.props.asset.metadata.assetType === 'collection' && this.props.collectionInURLQuery ? 
      collectionQueryURL : 
      `/${this.itemPath}/${this.props.asset.id}`) +
      (this.props.relatedHierarchy ? `?relatedHierarchy=${this.props.relatedHierarchy}` : '');

    return (
      <Link data-extension={this.props.asset.metadata.extension} data-asset-type={this.props.asset.metadata.assetType} data-asset-kind={this.props.asset.metadata.assetDomain} title={this.props.asset.name} className={this.props.asset.thumbnail ? 'ems-item ems-asset-list-item' : 'ems-item ems-asset-list-item ems-asset-list-item-no-thumbnail'} to={`${to}${this.props.token ? `?token=${this.props.token}` : ''}`}>
        <div className='ems-item-inner ems-asset-list-item-inner'>
          <div className='ems-thumbnail ems-asset-list-item-thumbnail'>
          <Thumbnail
            type='asset'
            className='ems-thumbnail-inner ems-asset-list-item-thumbnail-inner'
            asset={this.props.asset}
            token={this.props.token}
            listType={this.props.type}
            src={this.props.asset.thumbnail}/>
          </div>
          <div className='ems-asset-list-item-details'>
            <div className='ems-asset-list-item-details-inner'>
              <div className='ems-title ems-asset-list-item-title'>{this.props.asset.metadata.title ? this.props.asset.metadata.title : this.props.asset.name}</div>
              {this.props.assetMetadataList.length > 0 && <div className='ems-margin-top-quarter ems-metadata-list ems-asset-list-item-metadata-list'>
                {this.props.assetMetadataList.map(item => 
                  this.props.asset.metadata[item.metadata] ? <div key={item.metadata} className='ems-metadata-list-item ems-asset-list-item-metadata-list-item'>
                    <span className='ems-metadata-field ems-asset-list-item-metadata-list-item-field'>{item.name}</span> 
                    <span className='ems-metadata-value ems-value ems-item-view-content-details-metadata-item-value'>
                      { 
                        item.metadata === 'audioLength' || 
                        item.metadata === 'videoLength' ? 
                        msToTime(this.props.asset.metadata[item.metadata]) :
                        this.renderMetadataValue(this.props.asset.metadata[item.metadata])
                      }
                    </span>
                  </div> : false)}
              </div>}
            </div>
          </div>
          <div className='ems-asset-list-item-footer'>
            {!this.isStream && <button 
              className='ems-button ems-button-icon ems-button-basket ems-asset-list-item-button' 
              onClick={e => this.handleToggleBasket(e)} 
              title={this.props.addedToBasket ? 'Remove from basket' : 'Add to basket'}>

              {this.props.addedToBasket ? <i className='fas fa-shopping-basket error'></i> : <i className='fas fa-shopping-basket'></i> }

            </button>}
            {!this.isStream && <button className='ems-button ems-button-icon ems-button-download ems-asset-list-item-button' onClick={e => this.download(e)} title='Download'>
              <i className='fas fa-arrow-down'></i>
            </button>}
          </div>
            
        
        </div>
      </Link>
    );
  }
}

export default withRouter(AssetListItem);
